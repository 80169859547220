import $ from 'jquery'; // "Import JQuery"
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/carousel';


$(document).ready(function() {
    // Header mobile menu slide from the right
    $('[data-toggle="slide-collapse"]').on('click', function() {
        let navMenuCont = $($(this).data('target'))
        let heroCover = $(".hero")
        
        navMenuCont.toggleClass('shown')
        
        $('body').toggleClass('overflow-hidden')

        $('.navbar-brand img:first-child').toggleClass('d-none');
        $('.navbar-brand img:last-child').toggleClass('d-none');
    })
    // Close mobile menu
    $('.nav-link').on('click', function(){
        $('#navbarMainMenu').removeClass('shown')
        $('body').removeClass('overflow-hidden')
        $('.navbar-brand img:last-child').addClass('d-none');
        $('.navbar-brand img:first-child').removeClass('d-none');
    })

    // Change color of header menu on scroll after cover
    // $(document).on('scroll', function () {
    //     let heroCover = $(".hero")
    //     let barsMenu = $(".navbar-toggler .navbar-toggler-icon");
        
    //     barsMenu.toggleClass('black', $(this).scrollTop() > heroCover.height());
    // });

    // Cookie Banner
    $('#cookieBanner .close-banner').on('click', function(){
        sessionStorage.setItem('banner', 'true');
        $('#cookieBanner').hide()
    })

    if(!sessionStorage.getItem('banner')){
        $('#cookieBanner').show()
    }
})